.assetBoard {
  box-sizing: border-box;
  padding: 8px 24px;
  height: 100px;
  background: url('./assets/asset-board-bg.svg') 0 0/100% 100% no-repeat;
  box-shadow: 0 5px 20px rgba(30, 135, 240, 0.2);
  border-radius: 7.82px;
  overflow: hidden;

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #ffffff;
  }

  .content {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .balance {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    color: #ffffff;
  }

  .token {
    margin-left: 8px;
  }

  .icon {
    margin-left: 12px;
    width: 7px;

    polyline {
      stroke: #ffffff;
    }
  }

  .amount {
    margin-top: 4px;
    display: block;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
}

.selectCurrency {
  margin-top: 4px;
  padding: 30px 0;
  border-radius: 4px;

  .content {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 117px);
    grid-column-gap: calc(50% - 175.5px);
    margin-top: -16px;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    box-sizing: border-box;
    margin-top: 16px;
    padding: 16px 0;
    border-radius: 12px;
    transition: all 200ms;
    cursor: pointer;

    .name {
      margin-top: 8px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: var(--text-color-primary);
    }

    .fullName {
      font-size: 14px;
      line-height: 21px;
      color: var(--text-color-second);
    }

    .selected {
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }

  .item.active,
  .item:hover {
    box-shadow: 0 0 1px 1px #173DC9;
    background: #F5F7FF;
  }
}

.formItem {
  margin-top: 20px;
}