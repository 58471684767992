html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-weight: 400;
}

p, ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input {
  min-width: 0;
}

p, span {
  cursor: inherit;
}

:root {
  --color-primary: #173dc9;
  --color-primary-light: #2938ce;
  --color-primary-hover: #1b4af3;
  --color-primary-active: #0c30ae;

  --color-blue: #108ee9;
  --color-green: #8fce65;
  --color-red: #e02020;
  --color-yellow: #f0fa41;
  --color-white: #ffffff;

  --color-success: var(--color-green);
  --color-error: var(--color-red);
  --color-alert: var(--color-yellow);
  --color-info: var(--color-blue);

  --text-color-white: #ffffff;
  --text-color-primary: #333333;
  --text-color-normal: #666666;
  --text-color-second: #999999;

  --screen-small: 960px;
  --screen-middle: 1440px;
  --screen-large: 1920px;
}

/* overwrite ant style */
.ant-steps-item-process .ant-steps-item-icon {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--color-primary);
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-primary);
}