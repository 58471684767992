@mixin link ($link, $visit, $hover, $active) {
  color: $link;
  &:visited {
    color: $visit;
  }
  &:hover {
    color: $hover;   
  }
  &:active {
    color: $active;
  }
}

$breakpoints: (
  'sm': 'only screen and (max-width: 960px)',
  'md': 'only screen and (max-width: 1440px)',
  'lg': 'only screen and (min-width: 1440px)'
) !default;

@mixin media-breakpoint-up ($breakpoint) {
  $query: map-get($breakpoints, $breakpoint);

  @if not $query {
    @error 'No breakpoint found';
  }

  @media #{if(type-of($query) == 'string', unquote($query), inspect($query))} {
    @content;
  }
}