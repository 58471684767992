.root {
  .list {
    max-height: 500px;
    list-style: none;
    border-radius: 2px;
    border: 1px solid #ecf0f2;
    overflow: auto;
  }

  .item {
    position: relative;
    display: flex;
    font-size: 16px;
    line-height: 19px;
    padding: 14px 16px;
    cursor: pointer;
    transition: background 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      height: 1px;
      width: calc(100% - 50px);
      background: #ecf0f2;
    }

    &:hover {
      background: #f2f5f7;
    }

    &:last-child::after {
      display: none;
    }
  }

  .icon {
    margin-right: 16px;
  }

  .account {
    flex: 1;
  }

  .checked {
    width: 16px;
  }
}