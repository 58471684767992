.root {
  a, a:link, a:visited, a:hover, a:active {
    text-decoration: underline;
    color: #666666;
  }

  .pagination {
    margin: 20px 0;
    color: #666666;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 460px;
}