@import './styles/index.scss';

.aca-page {
  box-sizing: border-box;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
  padding: 0 40px;
  padding-bottom: 64px;
  background: $platform-background-color;
}

.aca-page__container {
  margin: 0 auto;
  max-width: 1120px;

  &.fullscreen {
    max-width: 100%;
  }
}

.aca-page__title, .aca-page__sub-title {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: var(--text-color-primary);
}

.aca-page__title {
  margin: 32px 0 40px 0;
}

.page-title--breadcrumb-item {
  font-size: 20px;
  margin-left: 12px;
  font-weight: normal;
}

.aca-page__content {
  margin-top: 16px;

  &.fullscreen {
    max-width: 100%;
  }
}