.root {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content {
  flex: 1;
  position: relative;
}