.root {
  margin-bottom: 16px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .label {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 19px;
  }

  .content {
    width: 100%;
  }
}