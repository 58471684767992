@import "../../../../ui-components/src/styles/index.scss";

.root {
  display: flex;
  flex-direction: column;
  z-index: 1;
  min-width: 240px;
  max-width: 240px;
  height: 100vh;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 20px 20px 0 rgba(13,28,90,0.09);
}

.logo {
  margin: 94px auto 44px auto;
  text-align: center;
}

.item {
  position: relative;
  display: flex;
  justify-items: flex-start;
  align-items: center;
  padding-left: 21px;
  height: 58px;
  font-size: $font-s;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-primary);
  transition: all 200ms ease;

  & > svg {
    margin-right: 13px;
  }

  &:hover:not(.collapse) {
    transform: translate3d(2px, 0, 0);
  }

  &:global(.active) {
    transform: translate3d(0, 0, 0);
  }

  &:hover:not(.collapse), &:global(.active) {
    background-image: linear-gradient(90deg, #E5EAFF 0%, #F5F6FA 100%);
  }

  &:global(.active)::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    background: var(--color-primary);
  }

  @include link(var(--color-primary), var(--color-primary), var(--color-primary), var(--color-primary));
}

.collapse {
  cursor: pointer;
  user-select: none;
}

.collapseArrow {
  margin-left: 8px;
  margin-bottom: 3px;
  color: var(--color-primary);

  &.open {
    svg {
      transform: rotate(-180deg);
    }
  }

  svg {
    transition: transform .2s cubic-bezier(0,0,.2,1) 0ms;
  }
}

.collapseList {
  .item {
    padding-left: 60px;
  }
}

.wallet {
  & > p:first-child {
    color: var(--color-primary);
  }
  color: #abb4d1;
}

.products {
  flex: 1;
}

.social {
  display: flex;
  padding: 0 24px;;

  & .item {
    margin-right: 12px;
    padding: 0;
    width: 24px;
    height: 24px;

    &:hover {
      transform: scale(1.1);
      background: none;
    }
  }

  & svg {
    margin: 0;
  }
}

.status {
  margin: 24px 24px 65px 24px;
}