@import '../../ui-components/src/styles/index.scss';

.root {
  position: relative;
  font-size: 16px;
  color: var(--text-color-primary);
  padding-left: 20px;
  transition: background 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translate3d(0, -4px, 0);
    background: #d8d8d8;
  }

  &.connected {
    color: var(--color-primary);
  }

  &.connected::before {
    background: var(--color-primary);
  }

  @include media-breakpoint-up('md') {
    p {
      display: none;
    }
  }
}