@import './styles/index.scss';

.root {
  box-sizing: border-box;
  display: grid;
  place-items: center;

  margin: 0 8px;
  padding: 0 8px;
  min-width: 30px;
  max-width: 100%;
  height: 32px;
  background: #f0f0f0;
  border-radius: 13px;
  overflow: hidden;

  font-weight: 500;
  text-overflow: ellipsis;

  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.white {
    border: 1px solid #e1e1e1;
    background: #ffffff;
  }

  &.primary {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
    color: $color-white;
  }

  &.clickable {
    cursor: pointer;
  }
}

.tagGroup {
  display: flex;

  > div {
    margin: 0 8px 0 0;
  }
  > span {
    margin: 0 8px 0 0;
  }

  .root:last-child {
    margin: 0;
  }
}