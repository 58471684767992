.aca-controller__root {
  min-width: 0 !important;
  padding: 0 !important;
  width: 40px;
  height: 24px !important;  
  border-radius: 24px;
  border: 1px solid #e9e9e9;
  background: #ffffff !important;
  transition: all 200ms ease;

  > svg {
    transition: all 200ms ease;
  }

  &:hover {
    border-color: var(--color-primary);

    > svg g {
      stroke: var(--color-primary);
    }
  }

  &.disabled {
    border-color: #e9e9e9 !important;
    cursor: not-allowed;

    > svg g {
      stroke: #98a5ae;
    }
  }


  > svg g {
    stroke: var(--text-color-primary);
  }

  &.left > svg {
    transform: rotateY(180deg);
  }
}

.aca-controller__group {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  grid-column-gap: 8px;
}