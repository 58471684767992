.root {
  display: flex;
  align-items: stretch;
  width: 512px;
  border: 1px solid #E9E9E9;
  transition: all 200ms cubic-bezier(0.23, 1, 0.320, 1);
  border-radius: 2px;

  &:hover, &.focused {
    border-color: var(--color-primary);
  }

  &.error {
    border-color: var(--color-error) !important;
  }

  .switchArea {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    padding: 0 24px;
    cursor: pointer;

    > svg {
      transition: all 200ms cubic-bezier(0.23, 1, 0.320, 1);
    }

    &:hover svg {
      transform: scale(1.1);
    }
  }

  .inputArea {
    height: 59.5px;
    border-bottom: 1px solid #E9E9E9;
  }

  .displayArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: 58.5px;

    font-size: 24px;
    line-height: 29px;
    color: #666666;

    > .token {
      display: flex;
      align-items: center;
    }

    .tokenName {
      font-size: 24px;
      line-height: 29px;
      color: var(--text-color-primary);
    }

    .tokenImage {
      width: 24px;
      line-height: 24px;
      margin: 0 16px;
    }
  }
}