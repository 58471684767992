.icon {
  margin-right: 8px;
}

.number, .balance {
  white-space: nowrap;

  // .decimal {
  //   font-size: 90%;
  // }

  &:global(.primary) {
    color: var(--color-primary);
  }

  &:global(.error) {
    color: var(--color-error);
  }

  &:global(.success) {
    color: var(--color-success);
  }
}
