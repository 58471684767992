@import './styles/index.scss';

.root {
  padding: 0 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  list-style: none;
  background: #ffffff;

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    top: 16px;
    left: 16px;
    right: 16px;
    background: #d8d8d8;
  }

  .item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: inherit;

    &::before, &::after {
      content: '';
      position: absolute;
      width: 20%;
      height: 1px;
      top: 16px;
      background: #d8d8d8;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    &:first-child::before {
      display: none;
    }

    &:last-child::after {
      display: none;
    }

    &.active {
      .point {
        background: var(--color-primary);
        border-color: var(--color-primary);
        color: #ffffff;
      }
      .text {
        font-weight: 500px;
        color: var(--text-color-primary);
      }
    }

    &.done {
      .point {
        border-color: var(--color-primary);
        color: var(--color-primary);

        & path {
          fill: var(--color-primary);
        }
      }

      .text {
        color: var(--text-color-primary);
      }
    }
  }

  .point {
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 100%;
    border: 1px solid var(--text-color-second);
    color: var(--text-color-second);
  }

  .text {
    margin-top: 16px;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    color: var(--text-color-second);
  }
}