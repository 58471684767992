.root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 24px;
  min-height: 120px;
  border-radius: 12px;
  background: #eef5ff;
}

.labelArea {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  list-style: none;
}

.label {
  position: relative;
  box-sizing: border-box;
  flex: 0 0 50%;
  padding-left: 16px;


  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;

  .decoration {
    position: absolute;
    left: 0;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transform: translate3d(0, -50%, 0);
  }

  .status {
    display: inline-block;
    margin-left: 6px;
  }
}