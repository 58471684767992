.cardContent {
  display: flex;
  padding-bottom: 24px;
  margin-top: -24px;
  overflow-x: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 4px;
  }
}

.item {
  flex: 0 0 20%;
  display: flex;
  align-items: stretch;
  border-right: 1px solid #ecf0f2; 
  margin-top: 48px;
  padding: 0 24px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  .image {
    min-width: 48px;
    max-width: 48px;
    height: 48px;
  }

  .name {
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 14px;
    line-height: 16px;
    color: var(--text-color-primary);
  }

  .balance {
    font-size: 24px;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
  }

  .amount {
    font-size: 16px;
    line-height: 19px;
    color: var(--text-color-second);
    white-space: nowrap;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 16px;
  }
}