
:global(.kTKCiE > .container):before {
  box-shadow: 0 0 1px 1px #e0e0e0 !important;
}

.input {
  font-size: 12px;
  line-height: 16px;
}

.option {
  display: grid;
  grid-template-columns: 44px 1fr;

  padding: 4px !important;
  font-size: 12px;
  line-height: 16px;

  .identicon {
    margin-right: 12px;
  }
}