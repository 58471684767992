.root {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: stretch;
  padding-left: 16px;
  transition: all 300ms cubic-bezier(0.0, 0, 0.2, 1);
  border-radius: 2px;

  &.noToken {
    grid-template-columns: 1fr;
  }

  &.disabled {
    input {
      background-color: #ffffff !important;
    }
  }

  &.middle {
    height: 48px;
  }

  &.large {
    height: 58px;

    .token {
      font-size: 24px;
    }
  }

  &.small {
    height: 48px;

    .token {
      font-size: 16px;
    }
  }

  &.mini {
    height: 34px;
    padding-left: 12px;

    .input {
      font-size: 14px;
      padding-right: 12px;
    }

    .token {
      font-size: 16px;
    }
  }

  &.hover:hover, &.focused {
    border-color: var(--color-primary);
  }

  &.error {
    border-color: var(--color-error) !important;
  }

  &.border {
    border: 1px solid #E9E9E9;
  }

  &.showIcon {
    grid-template-columns: 1fr 160px;
  }

  &.showMax {
    grid-template-columns: 1fr 64px 100px;
  }

  &.showMax.showIcon {
    grid-template-columns: 1fr 64px 160px;
  }

  .input {
    height: calc(100% - 2px);
    padding-right: 16px;
    color: var(--text-color-primary);
  }

  .tokenSelector {
    border: none !important;
    height: auto;
    border-left: 1px solid #E9E9E9 !important;
    border-radius: 0 !important;

    &:hover {
      border-left: 1px solid #E9E9E9;
    }

    &.showIcon {
      max-width: 160px;
    }

    &.left {
      border-left: 0;
      border-right: 1px solid #E9E9E9;
      margin-right: 16px;
    }
  }

  .token {
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-left: 1px solid #E9E9E9;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);
    overflow: hidden;

    &.showIcon {
      max-width: 160px;
    }

    > img {
      margin-right: 16px;
      width: 24px;
      height: 24px;
    }
  }

  .maxBtn {
    height: 0 !important;
    min-width: 0 !important;
    height: auto !important;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .error {
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    top: -36px;
    right: 0;
    padding: 4px 8px;
    background: var(--color-error);
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    font-weight: 500;

    &.show {
      opacity: 1;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      bottom: -4px;
      right: 18px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--color-error);
    }
  }
}
