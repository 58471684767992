.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 67px 0 38px 0 !important;
  font-size: 24px;
  line-height: 29px;
  color: #434e59;
}