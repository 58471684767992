.root {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: #ffffff;
  border-radius: 2px;
  transition: border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  &.border {
    border: 1px solid #E9E9E9;
  }

  &:hover, &.focused {
    border-color: var(--color-primary);
  }

  &:hover .suffix polyline {
    stroke: var(--color-primary);
  }

  &.error {
    border-color: var(--color-error) !important;
  }

  .maxBtn {
    min-width: 0 !important;
    height: auto;
    padding: 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .error {
    opacity: 0;
    position: absolute;
    box-sizing: border-box;
    top: -36px;
    right: 0;
    padding: 4px 8px;
    background: var(--color-error);
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    font-weight: 500;

    &.show {
      opacity: 1;
    }

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      bottom: -4px;
      right: 18px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--color-error);
    }
  }
}

.input {
  flex: 1;
  // clean default
  border: none;
  appearance: none;
  outline: none;
  box-shadow: none;

  color: rgba(0, 0, 0, 0.8);
  font-size: 22px;
  line-height: 26px;

  transition: all 200ms;
}

.prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}
