@import './styles/index.scss';

.root {
  border-radius: 12px;
  box-shadow: 0 0 30px 0 rgba(23, 65, 212, 0.12);
  border: 1px solid #ebeef5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  min-width: 264px;
  height: 150px;
  background: #ffffff;
}

.title {
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}

.content {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.value {
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  font-size: 24px;
  line-height: 1;
  width: 100%;
}
