@import './styles/index.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
  transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;

  // clear default apperance
  border: none;
  outline: none;
  box-shadow: none;
  appearance: none;

  // size
  &.large {
    min-width: 178px;
    height: 58px;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
  }

  &.middle {
    min-width: 120px;
    height: 42px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 16px;
  }

  &.small {
    min-width: 80px;
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 14px;
  }

  // type
  &.type-normal.color-primary {
    background: var(--color-primary);
    color: $color-white;

    &:hover {
      background: var(--color-primary-hover);
    }

    &:active {
      background: var(--color-primary-active);
    }

    &:hover.disabled,
    &.disabled {
      cursor: not-allowed;
      color: #d9d9d9; 
      background: var(--color-primary);
    }
  }

  &.type-normal.color-normal {
    background: #ecf0f2;
    color: var(--text-color-primary);
  }

  &.type-normal.color-danger {
    background: var(--color-error);
    color: var(--text-color-white);

    &:hover.disabled,
    &.disabled {
      cursor: not-allowed;
      color: #d9d9d9; 
      background: var(--color-error);
    }
  }

  &.type-border.color-primary {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);

    &:hover {
      color: var(--color-primary)-hover;
      border: 1px solid var(--color-primary-hover);
    }

    &:active {
      color: var(--color-primary)-active;
      border: 1px solid var(--color-primary-active);
    }
  }

  &.type-border.color-normal {
    color: var(--text-color-primary);
    border: 1px solid $normal-button-border-color;

    &:hover {
      color: var(--color-primary-hover);
      border: 1px solid var(--color-primary-hover);
    }

    &:active {
      color: var(--color-primary-active);
      border: 1px solid var(--color-primary-active);
    }
  }

  &.type-border.color-danger {
    color: var(--color-error);
    border: 1px solid var(--color-error);

    &:hover {
      color: var(--color-error);
      border: 1px solid var(--color-error)
    }

    &:active {
      color: var(--color-error);
      border: 1px solid var(--color-error)
    }
  }

  &.type-ghost.color-primary {
    background: transparent;
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }

    &:active {
      color: var(--color-primary-active);
    }
  }

  &.type-ghost.color-normal {
    background: transparent;
    color: var(--text-color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }

    &:active {
      color: var(--color-primary-active);
    }
  }

  .loading {
    margin-right: 4px;
  }
}

.iconButton {
  &.large {
    max-width: 58px;
    min-width: 58px;
    height: 58px;
    font-size: 20px;
  }

  &.middle {
    max-width: 42px;
    min-width: 42px;
    height: 42px;
    padding: 0 12px;
    font-size: 16px;
  }

  &.small {
    max-width: 32px;
    min-width: 32px;
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
  }
}