@import "./styles/index.scss";

.root {
  position: relative;
  height: 64px;
  transition: border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  font-weight: 500;

  .arrow > svg {
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  }
}

.root.normal {
  width: auto;
  background: #ffffff;

  &.small {
    height: 32px;

    .activeRoot:after {
      height: 22px;
    }

    .arrow {
      padding: 0 8px;
    }
    .arrow:after {
      display: none;
    }
  }

  .activeRoot {
    & > .activeAction {
      flex: 0;
    }

    &:after {
      display: none;
    }
  }

  .menu {
    min-width: 200px;
  }
}

.root.border {
  border-radius: 13px;
  border: 1px solid var(--color-primary);
  background: #ffffff;

  &:hover {
    border-color: #4564D4;
  }

  &.small {
    height: 32px;
    width: 110px;

    .arrow:after {
      height: 22px;
    }
  }
}

.activeRoot {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;

  & > .activeContent {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 0 0 12px;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-primary);

    & > svg {
      margin-right: 8px;
    }
  }

  & > .arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
  }
}

.menu {
  z-index: 99;
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  margin: 0;
  padding: 4px 0;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  list-style: none;
  background: #ffffff;

  .menuItem {
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 64px;
    color: var(--color-primary);

    & > svg {
      margin-right: 8px;
    }

    &:hover {
      background: #f2f5f7;
    }
  }
}

.open {

  .arrow > svg {
    transform: rotateZ(-180deg);
  }
  .menu {
    display: block;
  }
}
