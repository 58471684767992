@import "styles/index.scss";

.root {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  box-shadow: 0 1px 20px 0 rgba(23,65,212,0.02);
  border-radius: 12px;

  &.overflowHidden {
    overflow: hidden;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  color: var(--text-color-primary);
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;

  .extra {
    font-size: 16px;
    font-weight: normal;
    color: var(--text-color-second);
  }

  &.divider {
    font-size: 20px;
    line-height: 24px;
    border-bottom: 1px solid #ecf0f2;
  }
}

.content {
  box-sizing: border-box;
  width: 100%;
}

.content.padding {
  padding: 0 24px 24px 24px;
}

.content.noTitleContent {
  padding: 24px;
}