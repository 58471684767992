
.aca-tabs {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;

  .aca-tabs__tabs {
    display: flex;
    list-style: none;
    margin: 0;
    color: var(--text-color-second);

    user-select: none;
  }

  & .aca-tabs__tab {
    cursor: pointer;
    transition: all 300ms cubic-bezier(0.0, 0, 0.2, 1);
    background: #ffffff;

    &.disabled {
      filter: grayscale(1) opacity(0.65);
      cursor: not-allowed !important;
    }
  }
}

.aca-tabs--card {
  & > .aca-tabs__tabs {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
  }

  & > .aca-tabs__tabs > .aca-tabs__tab {
    flex: 1;
    height: 64px;
    text-align: center;
    background-image: linear-gradient(0deg, #FBFBFB 0%, #EDEFF2 100%);
    background: #ECF0F2;
    font-size: 20px;
    line-height: 64px;
    color: #666666;
    font-weight: 500;

    &.active {
      background: #ffffff;
      color: var(--text-color-primary);
    }
  }
}

.aca-tabs--button {
  & > .aca-tabs__tabs {
    margin-left: 1px;
  }

  & > .aca-tabs__tabs > .aca-tabs__tab {
    margin-left: -1px;
    border: 1px solid #E9E9E9; 
    height: 40px;
    min-width: 160px;
    padding: 0 8px;
    line-height: 38px;
    text-align: center;

    &.active {
      z-index: 1; // upper z-index
      border: 1px solid var(--color-primary); 
      color: var(--color-primary);
    }

    &:hover {
      border: 1px solid var(--color-primary); 
      border-left: 1px solid var(--color-primary);
      border-right: 1px solid var(--color-primary);
      color: var(--color-primary);
    }

    &:hover + li, &.active + li {
      border-left: 1px solid var(--color-primary);
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.aca-tabs__content {
  margin-top: 24px;
}

.aca-tabs--line {
  & > .aca-tabs__tabs {
    border-bottom: 1px solid #ecf0f2;
  }

  & > .aca-tabs__tabs > .aca-tabs__tab {
    position: relative;
    margin-right: 60px;
    padding: 16px 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;

    &.active {
      z-index: 1; // upper z-index
      color: var(--color-primary);
    }

    &.active::after {
      position: absolute;
      content: '';
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--color-primary);
    }
  }

}