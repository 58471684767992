@import "./styles//variables.scss";

.root {
  width: 100%;
  border-collapse:collapse;
  color: $color-gray;
}

.root.border {
  border: 1px solid #ecf0f2;
}

.headerCell {
  padding: 14px 8px 15px 8px;
  border-bottom: 1px solid #ecf0f2;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: var(--text-color-primary);
}

.cell {
  height: 64px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #ecf0f2;
  font-size: $font-m;
  cursor: default;

  & > div {
    display: flex;
    align-items: center;
  }
}

.headerCell.left, .cell.left {
  text-align: left;

  & > div {
    justify-content: flex-start;
  }
}

.headerCell.right, .cell.right {
  text-align: right;

  & > div {
    justify-content: flex-end;
  }
}

.headerCell.center, .cell.center {
  text-align: center;

  & > div {
    justify-content: center;
  }
}

.row {
  transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.row:last-child {
  & > .cell {
    border-bottom: none;
  }
}

.headerCell:first-child, .cell:first-child {
  padding-left: 24px;
}

.headerCell:last-child, .cell:last-child {
  padding-right: 24px;
}

.row:hover {
  background: $table-active-color;
}

.empty {
  height: 300px;
  font-size: 16px;
  text-align: center;
  color: #666666;
}

.small {
  .cell {
    height: auto;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}