.root {
  display: flex;
  align-items: stretch;

  &.padding {
    margin: 0 4px;
  }

  .nameArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .fullname {
    font-size: 14px;
    line-height: 21px;
    color: var(--text-color-second);
    white-space: nowrap;
  }
}

.tokenImg {
  display: block;
  width: 42px;
  height: 42px;
}

.icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 12px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #ffffff;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &.noName {
    margin-right: 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}