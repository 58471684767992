
$select-height: 64px;

.aca-select__root {
  min-width: 250px;
  height: $select-height;

  .ant-select-selector {
    box-shadow: none !important;
    height: $select-height !important;
    align-items: center;
    font-weight: 500 !important;
    border-radius: 13px !important;
    border-color: var(--color-primary) !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: var(--color-primary) !important;
  }

  .ant-select-arrow {
    padding: 0 18px;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      height: 44px;
      width: 1px;
      background: #ebeef5;
      transform: translate3d(0, -50%, 0);
    }
  }

}

.aca-select__dropdown {
  z-index: 99;
  top: calc(100% + 4px);
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  list-style: none;
  background: #ffffff;
}

.aca-select__option-item {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 64px;
  color: var(--color-primary) !important;
  background: #ffffff !important;

  .ant-select-item-option-content > svg {
    margin-right: 8px;
  }

  &:hover {
    background: #f2f5f7 !important;
  }
}