.root {
  box-sizing: border-box;
  width: 100%;
  height: 96px;
  border: 1px solid #E9E9E9;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 64px calc(100% - 64px);

  &:hover, &.focused {
    border-color: var(--color-primary);
  }

  &.error {
    border-color: var(--color-error) !important;
  }

  .switch {
    display: grid;
    place-items: center;
    cursor: pointer;
  }

  .balanceInput {
    padding-left: 0 !important;
    height: 48px !important;
    border-bottom: 1px solid #E9E9E9 !important;
    font-size: 16px !important;
    input {
      height: 46px !important;
    }
  }

  .amountDisplay {
    height: 48px;
    display: flex;
    padding-right: 16px;
    justify-content: space-between;
    align-items: center;

    font-size: 16px;
    line-height: 19px;
    color: var(--text-color-primary);
  }

  .amountInput {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #E9E9E9
  }

  .balanceDisplay {
    padding-left: 0 !important;
    height: 46px !important;
    border: none;

    & > input:disabled {
      background: #ffffff;
      font-size: 16px;
    }
  }
}