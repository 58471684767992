$font-xl: 24px;
$font-l: 20px;
$font-m: 16px;
$font-s: 14px;

$color-primary: var(--color-primary);
$color-primary-hover: #1b4af3;
$color-primary-active: #0c30ae;

$color-white: #ffffff;
$color-blue: var(--text-color-primary);
$color-gray: #666666;

$platform-background-color: #f8f9fd;


/* button */
$normal-button-border-color: #E9E9E9;

/* table */
$table-border-color: #ebeef5;
$table-cell-border-color: #ecf0f2;
$table-active-color: #f2f5f7;

$shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

$top: 99;
$middle: 9;
$normal: 0;