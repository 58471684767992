@import './styles/index.scss';

$loader-width: 4px;
$loader-width-small: 2px;

.root.normal {
  width: 40px;
  height: 40px;

}

.root.small {
  width: 14px;
  height: 14px;
  .loader {
    border-width: $loader-width-small;

    &::before {
      top: -$loader-width-small;
      left: -$loader-width-small;
      bottom: -$loader-width-small;
      right: -$loader-width-small; 
      border-width: $loader-width-small;
    }
  }
}

.root {
  .loader {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    background: transparent;
    border: $loader-width solid #dddddd;
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: -$loader-width;
      left: -$loader-width;
      bottom: -$loader-width;
      right: -$loader-width;
      box-sizing: border-box;
      background: inherit;
      border-radius: inherit;
      border: $loader-width solid var(--color-primary);
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
      animation: circle 600ms linear;
      animation-iteration-count: infinite;
      transform-origin: center center;
    }
  }
}

.fullscreen {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  box-shadow: 0 1px 20px 0 rgba(23, 65, 212, 0.02);
  border-radius: 12px;
}


@keyframes circle {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}